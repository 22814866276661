<template>
  <v-card color="white" elevation="1" class="pa-3">
    <v-container>
      <v-row class="mb-3">
        <v-col
          ><h2>{{ value.journal_entry_number }}</h2></v-col
        >
      </v-row>
      <!--Journal Entry-->
      <v-row>
        <v-col lg="3" md="3" v-for="header in entryHeaders" :key="header.name">
          <h4>{{ header.name }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="3" md="3" v-for="item in entryData" :key="item.name">
          <p>{{ item.name }}</p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <!--Bank Transaction-->
      <v-row class="mt-3"
        ><v-col lg="3" md="3" v-for="header in bankHeaders" :key="header.name">
          <h4>{{ header.name }}</h4>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col lg="3" md="3">
          {{ this.bank_transaction.transaction_number }}
        </v-col>
        <v-col lg="3" md="3">
          {{ this.bank_transaction.bank_account_name }}
        </v-col>
        <v-col lg="3" md="3">
          {{ this.bank_transaction.transaction_type }}
        </v-col>
      </v-row>
      <!--Attachments-->
      <div v-if="this.bank_transaction.attachments[0]">
        <v-divider></v-divider>
        <v-row class="mt-3">
          <v-col lg="3" md="3">
            <h4>Source Documents</h4>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col>
            <v-icon small left>mdi-attachment</v-icon
            ><a
              :href="this.bank_transaction.attachments[0].src"
              target="blank"
              style="text-decoration: none"
              >{{ this.bank_transaction.attachments[0].file_name }}</a
            >
            <div class="mt-3">
              <!--Image-->
              <v-img
                v-if="
                  this.bank_transaction.attachments[0].file_name.slice(-3) !==
                  'pdf'
                "
                max-width="100%"
                width="100%"
                :src="this.bank_transaction.attachments[0].src"
              ></v-img>
              <!--PDF Iframe-->
              <iframe
                style="border: none"
                v-else
                max-width="100%"
                width="100%"
                height="500px"
                :src="this.bank_transaction.attachments[0].src"
              >
              </iframe>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-card>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "JournalEntryPreviewModal",
  props: ["value"],
  data() {
    return {
      entryHeaders: [
        { name: "Date" },
        { name: "Entry Number" },
        { name: "Ledger" },
        { name: "Amount" },
      ],
      entryData: [
        { name: this.value.journal_entry_date },
        { name: this.value.journal_entry_number },
        { name: this.value.account_name },
        { name: formatAsCurrency("R", this.value.entry_amount) },
      ],
      bankHeaders: [
        { name: "Transaction Number" },
        { name: "Bank Account" },
        { name: "Transaction Type" },
      ],
      bank_transaction: {},
    };
  },
  created() {
    this.getBankTransaction();
  },
  methods: {
    getBankTransaction() {
      const docRef = db
        .collection("bank_transactions")
        .where("transaction_number", "==", this.value.allocation_status)
        .limit(1);
      docRef
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const data = doc.data();
            this.bank_transaction = { ...data };
          });
        })
        .catch((error) => error);
    },
  },
};
</script>